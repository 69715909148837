import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label,Spinner } from "reactstrap";
import Select from "react-select";
import ReactSelect from "react-select";
import {
  getAllFundSource,
  addFundTransfer,
  getAllFundTransfer,
} from "../../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import "./fundpage.scss";
import SweetAlert from "react-bootstrap-sweetalert";
import moment from "moment";
import { CSVLink } from 'react-csv';




const Fund = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [fundTransferList, setFundTransferList] = useState([]);
  const [master, setMaster] = useState({});
  const [selectedFundSourceFrom, setSelectedFundSourceFrom] = useState(null);
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState(null);
  const [userPrivilege, setUserPrivilege] = useState(null);
  const [selectedFundSourceTo, setSelectedFundSourceTo] = useState(null);
  const [statusToBeUpdated, setStatusToBeUpdated] = useState(null)
  const [fundSourceOptions, setFundSourceOptions] = useState([]);
  const [fromfundSourceOptions, setfromFundSourceOptions] = useState([]);
  const [commisionPer, setcommisionPer] = useState(0);
  const [confirmAlert, setConfirmAlert] = useState(false)
  const [userId, setUserId] = useState(1);
  const [fundSources, setFundSources] = useState([]);
  const [fundToSources, setFundToSources] = useState([]);
  const [data, setData] = useState([])
  const [filters, setFilters] = useState({
    from_date: "",
    to_date: "",
    fund_transfer_from: null,
    fund_transfer_to: null,
  });

  const {
    fundTransfers,
    addingFundTransfer,
    addFundTransferResponse,
    fundSource,
    error,
  } = useSelector((state) => state.fundTransfers);

  const dispatch = useDispatch();
  const formRef = useRef();
  const getFromSources = () => {
    axios
      .get(`${API_URL}chart_of_account/from_fund_source`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var list = [];
        res.data?.data?.map((item) => {
          list.push({
            label: item.acc_chart_name,
            value: item.acc_chart_id,
            amount: item?.acc_chart_balance,
          });
        });
        setfromFundSourceOptions(list);
      });
  };
  useEffect(() => {
    dispatch(getAllFundSource());
    dispatch(getAllFundTransfer());
    getFromSources();
  }, []);

  useEffect(() => {
    if (addFundTransferResponse.type === "success") {
      toastr.success(addFundTransferResponse.message);
    } else if (addFundTransferResponse.type === "failure") {
      toastr.error(error.data.message, addFundTransferResponse.message);
    }
  }, [addFundTransferResponse]);

  useEffect(() => {
    var data =
      fundSource &&
      fundSource.map((item) => {
        return {
          label: item.acc_chart_name,
          value: item.acc_chart_id,
          //amount:item?.acc_chart_balance
        };
      });

    setFundSourceOptions([
      {
        options: data,
      },
    ]);
  }, [fundSource]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = jwtDecode(localStorage.getItem("authUser"));
      setUserPrivilege(obj.Privilege);
      setUserId(obj.user);
    }
  }, [userId]);

  useEffect(() => {
    let fundTransferData = JSON.parse(JSON.stringify(fundTransfers));

    let data = [];
    fundTransferData.map((item, index) => {
      item.x_User = item.fname;
      item.fname = "";
      if (item.User) {
        item.fname = item.User.fname + " " + item.User.lname;
      }
      item.auth_userid = userId;
      item.id = index + 1;
      item.time1 = moment(item?.time, "HH:mm s").format("hh:mm a")
      item.amount_column = (
        <div className="text-right">{item.fund_transfer_amount.toFixed(2)}</div>
      );

      if (item?.fund_transfer_amount_refund === 0) {
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="success"
              style={{ fontSize: "smaller", padding: "3px 17px" }}
              onClick={() => {
                setStatusToBeUpdated(item?.fund_transfer_id)
                setConfirmAlert(true)
              }}
            >
              Refund
            </Button>
          </div>
        )
      } else {
        item.action = (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button color="danger"
              style={{ fontSize: "smaller", padding: "3px 10px" }}
            // onClick={() => updateStatus(item)}
            >
              Refunded
            </Button>
          </div>
        )
      }

      data.push(item);
    });
    setFundTransferList(data);
  }, [fundTransfers]);

  const handleChangeInput = (e) => {
    if (e.target.name == "fund_transfer_amount_actual") {
      var commision = (e.target.value * commisionPer) / 100;
      var amount = parseFloat(e.target.value) + commision;
      setMaster({
        ...master,
        ["auth_userid"]: userId,
        [e.target.name]: e.target.value,
        "commision": commisionPer,
        "fund_transfer_amount": amount
      });

    }
    else {
      setMaster({
        ...master,
        ["auth_userid"]: userId,
        [e.target.name]: e.target.value,
      });
    }

  };

  // function updateStatus(item) {
  //   let data = {
  //     transfer_id: item.fund_transfer_id
  //   }
  //   axios
  //     .post(`${API_URL}fund_transfer/refund`, data, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       },
  //     }).then(res => {
  //       if (res.data.success === true) {
  //         setFundTransferList()
  //       } else {
  //         toastr.error("No Balance")
  //       }
  //     })
  // }
  // const handleSelectedFundSource = (selected, name) => {
  //   switch (name) {
  //     case "from":
  //       if ((((userPrivilege == 1 || userPrivilege == 3) && selected.value == 5) || (userPrivilege != 1 && userPrivilege != 3)) && selectedFundSourceTo) {
  //         axios
  //           .get(`${API_URL}chart_of_account/get_user?chart_id=` + selectedFundSourceTo.value, {
  //             headers: {
  //               "x-access-token": accessToken,
  //             },
  //           })
  //           .then((res) => {
  //             var data = res.data.data;
  //             var commision = 0;
  //             if (data == "shop")
  //               commision = (2);
  //             else if (data.previlage == 6)
  //               commision = (9);
  //             else if (data.previlage == 7)
  //               commision = (7);
  //             else if (data.previlage == 8)
  //               commision = (6);
  //             else
  //               commision = (0);

  //             setcommisionPer(commision);
  //             if (master?.fund_transfer_amount_actual) {
  //               var commision_amt = (master?.fund_transfer_amount_actual * commision) / 100;
  //               var amount = parseFloat(master?.fund_transfer_amount_actual) + commision_amt;
  //               setMaster({
  //                 ...master,
  //                 "fund_transfer_from": selected.value,
  //                 "commision": commision,
  //                 "fund_transfer_amount": amount
  //               });
  //             }
  //             else {
  //               setMaster({
  //                 ...master,
  //                 "fund_transfer_from": selected.value,
  //                 "commision": commision,
  //                 "fund_transfer_amount": master?.fund_transfer_amount_actual
  //               });
  //             }


  //           });
  //       }
  //       else {
  //         setcommisionPer(0);
  //         setMaster({
  //           ...master,
  //           ["fund_transfer_from"]: selected.value,
  //           "commision": 0,
  //           "fund_transfer_amount": master?.fund_transfer_amount_actual
  //         });
  //       }
  //       setSelectedFundSourceFrom(selected);
  //       break;
  //     case "to":
  //       if ((((userPrivilege == 1 || userPrivilege == 3) && selectedFundSourceFrom?.value == 5) || (userPrivilege != 1 && userPrivilege != 3)) && selectedFundSourceFrom) {
  //         axios
  //           .get(`${API_URL}chart_of_account/get_user?chart_id=` + selected.value, {
  //             headers: {
  //               "x-access-token": accessToken,
  //             },
  //           })
  //           .then((res) => {
  //             var data = res.data.data;
  //             var commision = 0;
  //             if (data == "shop")
  //               commision = (2);
  //             else if (data.previlage == 6)
  //               commision = (9);
  //             else if (data.previlage == 7)
  //               commision = (7);
  //             else if (data.previlage == 8)
  //               commision = (6);
  //             else
  //               commision = (0);

  //             setcommisionPer(commision);
  //             if (master?.fund_transfer_amount_actual) {
  //               var commision_amt = (master?.fund_transfer_amount_actual * commision) / 100;
  //               var amount = parseFloat(master?.fund_transfer_amount_actual) + commision_amt;
  //               setMaster({
  //                 ...master,
  //                 "fund_transfer_to": selected.value,
  //                 "commision": commision,
  //                 "fund_transfer_amount": amount
  //               });
  //             }
  //             else {
  //               setMaster({
  //                 ...master,
  //                 "fund_transfer_to": selected.value,
  //                 "commision": commision,
  //                 "fund_transfer_amount": master?.fund_transfer_amount_actual
  //               });
  //             }
  //           });
  //       }
  //       else {
  //         setcommisionPer(0);
  //         setMaster({
  //           ...master,
  //           ["fund_transfer_to"]: selected.value,
  //           "commision": 0,
  //           "fund_transfer_amount": master?.fund_transfer_amount_actual
  //         });
  //       }
  //       setSelectedFundSourceTo(selected);

  //       break;
  //     default:
  //       break;
  //   }
  // };

  const handleSelectedFundSource = (selected, name) => {
    switch (name) {
      case "from":
        if ((((userPrivilege == 1 || userPrivilege == 3) && selected.value == 5) || (userPrivilege != 1 && userPrivilege != 3)) && selectedFundSourceTo) {
          axios
            .get(`${API_URL}chart_of_account/get_user?chart_id=` + selectedFundSourceTo.value, {
              headers: {
                "x-access-token": accessToken,
              },
            })
            .then((res) => {
              var data = res.data.data;   
              var commision = 0;
              if (data == "shop")
                // commision = (2);
                commision = (0);
              else if (data.previlage == 6)
                // commision = (1);
                commision = (0);
              else if (data.previlage == 7)
                // commision = (1);
                commision = (0);
              else if (data.previlage == 8)
                commision = (0);
              // commision = (2);
              else
                commision = (0);

              setcommisionPer(commision);
              if (master?.fund_transfer_amount_actual) {
                var commision_amt = (master?.fund_transfer_amount_actual * commision) / 100;
                var amount = parseFloat(master?.fund_transfer_amount_actual) + commision_amt;
                setMaster({
                  ...master,
                  "fund_transfer_from": selected.value,
                  "commision": commision,
                  "fund_transfer_amount": amount
                });
              }
              else {
                setMaster({
                  ...master,
                  "fund_transfer_from": selected.value,
                  "commision": commision,
                  "fund_transfer_amount": master?.fund_transfer_amount_actual
                });
              }


            });
        }
        else {
          setcommisionPer(0);
          setMaster({
            ...master,
            ["fund_transfer_from"]: selected.value,
            "commision": 0,
            "fund_transfer_amount": master?.fund_transfer_amount_actual
          });
        }
        setSelectedFundSourceFrom(selected);
        break;
      case "to":
        if ((((userPrivilege == 1 || userPrivilege == 3) && selectedFundSourceFrom?.value == 5) || (userPrivilege != 1 && userPrivilege != 3)) && selectedFundSourceFrom) {
          axios
            .get(`${API_URL}chart_of_account/get_user?chart_id=` + selected.value, {
              headers: {
                "x-access-token": accessToken,
              },
            })
            .then((res) => {
              var data = res.data.data;
              var commision = 0;
              if (data == "shop")
                // commision = (2);
                commision = (0);
              else if (data.previlage == 6)
                // commision = (1);
                commision = (0);
              else if (data.previlage == 7)
                // commision = (1);
                commision = (0);
              else if (data.previlage == 8)
                // commision = (2);
              commision = (0);
              else
                commision = (0);

              setcommisionPer(commision);
              if (master?.fund_transfer_amount_actual) {
                var commision_amt = (master?.fund_transfer_amount_actual * commision) / 100;
                var amount = parseFloat(master?.fund_transfer_amount_actual) + commision_amt;
                setMaster({
                  ...master,
                  "fund_transfer_to": selected.value,
                  "commision": commision,
                  "fund_transfer_amount": amount
                });
              }
              else {
                setMaster({
                  ...master,
                  "fund_transfer_to": selected.value,
                  "commision": commision,
                  "fund_transfer_amount": master?.fund_transfer_amount_actual
                });
              }
            });
        }
        else {
          setcommisionPer(0);
          setMaster({
            ...master,
            ["fund_transfer_to"]: selected.value,
            "commision": 0,
            "fund_transfer_amount": master?.fund_transfer_amount_actual
          });
        }
        setSelectedFundSourceTo(selected);

        break;
      default:
        break;
    }
  };

  const handleValidSubmit = (e, v) => {
    if (selectedFundSourceFrom == null) {
      toastr.error("From fund source is required");
      return;
    }
    if (selectedFundSourceTo == null) {
      toastr.error("To fund source is required");
      return;
    }
    dispatch(addFundTransfer(master));

    formRef.current.reset();
    setcommisionPer(0);
    setSelectedFundSourceFrom(null);
    setSelectedFundSourceTo(null);
    setMaster({ ["auth_userid"]: userId });
    dispatch(getAllFundTransfer());
    getFromSources();
  };

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;
    setFilters({
      ...filters,
      [name]: value,
    });
  };
  const handleSelectChange = (selectedOption, name) => {
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: selectedOption
    }));
  };


  useEffect(() => {
    const getFundSources = async () => {
      try {
        const response = await axios.get(API_URL + "fund_transfer/fundsource-list", {
          headers: {
            'x-access-token': accessToken
          }
        });
        setFundSources(response.data.data.map(source => ({ value: source.acc_chart_id, label: source.acc_chart_name })));
      } catch (error) {
        console.error("Error fetching fund sources:", error);
      }
    };

    getFundSources();
  }, []);


  useEffect(() => {
    const getFundToSources = async () => {
      try {
        const response = await axios.get(API_URL + "chart_of_account/fund_source", {
          headers: {
            'x-access-token': accessToken
          }
        });
        setFundToSources(response.data.data.map(source => ({ value: source.acc_chart_id, label: source.acc_chart_name })));
      } catch (error) {
        console.error("Error fetching fund sources:", error);
      }
    };

    getFundToSources();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await axios.get(API_URL + "fund_transfer", {
          params: {
            from_date: filters.from_date,
            to_date: filters.to_date,
            fund_transfer_from: filters.fund_transfer_from?.value,
            fund_transfer_to: filters.fund_transfer_to?.value,
          },
          headers: {
            'x-access-token': accessToken
          }
        });
        setData(response.data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setErrors(error); 
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }, [filters]);

  const reset = () => {
    formRef.current.reset();
    setcommisionPer(0);
    setMaster({});
    setSelectedFundSourceFrom(null);
    setSelectedFundSourceTo(null);
  };

  const resets = () => {
    setFilters({
      from_date: '',
      to_date: '',
      fund_transfer_from: 'null',
      fund_transfer_to: 'null',
      shop: ''
    });
  }
  // const data = {
  //   columns: [
  //     {
  //       label: "#",
  //       field: "id",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Date",
  //       field: "date",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Time",
  //       field: "time1",
  //       sort: "asc",
  //       width: 150,
  //     },
  //     {
  //       label: "Reference ID",
  //       field: "fund_transfer_unqid",
  //       sort: "asc",
  //       width: 400,
  //     },
  //     {
  //       label: "Transfer From",
  //       field: "found_from_name",
  //       sort: "asc",
  //       width: 200,
  //     },
  //     {
  //       label: "Transfer To",
  //       field: "found_to_name",
  //       sort: "asc",
  //       width: 200,
  //     },
  //     {
  //       label: "Amount",
  //       field: "amount_column",
  //       sort: "asc",
  //       width: 200,
  //     },
  //     {
  //       label: "Comment",
  //       field: "fund_transfer_comment",
  //       sort: "asc",
  //       width: 200,
  //     },
  //     {
  //       label: "Fund Transfer",
  //       field: "action",
  //       sort: "asc",
  //       width: 200,
  //     },
  //     {
  //       label: "User",
  //       field: "x_User",
  //       sort: "asc",
  //       width: 200,
  //     },
  //   ],
  //   rows: datas,
  // };


  // Define columns for the MDBDataTable
  const columns = [
    { label: 'No', field: 'No', sort: 'asc' },
    { label: "Transfer ID", field: "fund_transfer_unqid", sort: "asc" },
    { label: "Date", field: "date", sort: "asc" },
    { label: "Time", field: "time", sort: "asc" },
    { label: "Amount", field: "fund_transfer_amount", sort: "asc" },
    { label: "Transfer From", field: "found_from_name", sort: "asc" },
    { label: "Transfer To", field: "found_to_name", sort: "asc" },
    { label: "Commission", field: "fund_transfer_commision_amount", sort: "asc" },
    { label: "Comment", field: "fund_transfer_comment", sort: "asc" },
    { label: "Branch Name", field: "branch_name", sort: "asc" },
    { label: "Action", field: "action", sort: "asc" },
    { label: "Processed By", field: "processed_by", sort: "asc" },

  ];


  const rows = data.map((item, index) => ({
    No: index + 1,
    fund_transfer_unqid: item.fund_transfer_unqid,
    date: item.date,
    time: item.time,
    fund_transfer_amount: item.fund_transfer_amount.toLocaleString("en-IN", { style: "currency", currency: "INR" }),
    found_from_name: item.found_from_name,
    found_to_name: item.found_to_name,
    fund_transfer_commision_amount:item.fund_transfer_commision_amount,
    fund_transfer_comment: item.fund_transfer_comment,
    branch_name: item.branch_name,
    processed_by: `${item.fname} ${item.lname}`,
    action: item.fund_transfer_amount_refund === 0
      ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button color="success"
            style={{ fontSize: "smaller", padding: "3px 17px" }}
            onClick={() => {
              setStatusToBeUpdated(item?.fund_transfer_id);
              setConfirmAlert(true);
            }}
          >
            Refund
          </Button>
        </div>
      )
      : (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button color="danger"
            style={{ fontSize: "smaller", padding: "3px 10px" }}
          // onClick={() => updateStatus(item)}
          >
            Refunded
          </Button>
        </div>
      )
  }));

  const dataTableData = { columns, rows };
  const exportData = data.map((item, index) => ({
    No: index + 1,
    "Transfer ID": item.fund_transfer_unqid,
    Date: item.date,
    Time: item.time,
    Amount: item.fund_transfer_amount,
    "Transfer From": item.found_from_name,
    "Transfer To": item.found_to_name,
    Comment: item.fund_transfer_comment,
  }));
  const getFileName = () => {
    const fromName = filters && filters.fund_transfer_from && filters.fund_transfer_from.label ? filters.fund_transfer_from.label : 'All';
    const toName = filters && filters.fund_transfer_to && filters.fund_transfer_to.label ? filters.fund_transfer_to.label : 'Data';
    return `fund_transfer_list_${fromName}_to_${toName}.csv`;
  };

  // const loadingTextStyle = {
  //   fontSize: '1.5rem',
  //   color: '#007bff', 
  //   animation: 'pulse 1.5s infinite'
  // };

//   const keyframesStyle = `
//   @keyframes pulse {
//     0% {
//       opacity: 1;
//     }
//     50% {
//       opacity: 0.5;
//     }
//     100% {
//       opacity: 1;
//     }
//   }
// `;
//   useEffect(() => {
//     const styleSheet = document.createElement("style");
//     styleSheet.type = "text/css";
//     styleSheet.innerText = keyframesStyle;
//     document.head.appendChild(styleSheet);

//     return () => {
//       document.head.removeChild(styleSheet);
//     };
//   }, []);
  return (
    <React.Fragment>

      {confirmAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Yes"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          cancelBtnText="No"
          onConfirm={() => {
            axios
              .post(`${API_URL}fund_transfer/refund`, { transfer_id: statusToBeUpdated }, {
                headers: {
                  "x-access-token": accessToken,
                },
              }).then(res => {
                if (res.data.success === true) {
                  setFundTransferList([])
                  setConfirmAlert(false)
                } else {
                  toastr.error(res.data.message)
                }
              }).catch(err => {
                toastr.error("Failed to refund")
              })
          }}
          onCancel={() => setConfirmAlert(false)}
        >
          Are you sure?
        </SweetAlert>
      ) : null}

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Fund Transfer" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>From Fund Source</Label>
                          <Select
                            name="From_Fund_Source"
                            value={selectedFundSourceFrom}
                            onChange={(value) => {
                              handleSelectedFundSource(value, "from");
                            }}
                            options={fromfundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                        {selectedFundSourceFrom ? (
                          <span className="balance-box">
                            Balance :{" "}
                            {parseFloat(
                              selectedFundSourceFrom?.amount
                            ).toLocaleString()}
                          </span>
                        ) : null}
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>To Fund Source</Label>
                          <Select
                            name="To_Fund_Source"
                            value={selectedFundSourceTo}
                            onChange={(value) => {
                              handleSelectedFundSource(value, "to");
                            }}
                            options={fundSourceOptions}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Amount</Label>
                          <AvField
                            name="fund_transfer_amount_actual"
                            placeholder="Amount"
                            type="number"
                            errorMessage="Enter Amount"
                            className="form-control"
                            // validate={{ required: { value: true }, min: { value: userPrivilege < 8 ? 10000 : 10000, errorMessage: "Minimum transaction amount should be Rs. " + (userPrivilege < 8 ? 10000 : 10000) } }}
                            validate={{ required: { value: true }, min: { value: userPrivilege < 8 ? 1000 : 1000, errorMessage: "Minimum transaction amount should be Rs. " + (userPrivilege < 8 ? 1000 : 1000) } }}
                            id="validationCustom05"
                            value={master?.fund_transfer_amount_actual}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Amount Transfering with {commisionPer}% commision</Label>
                          <AvField
                            name="fund_transfer_amount"
                            placeholder="Amount"
                            type="number"
                            readOnly={true}
                            errorMessage="Enter Amount"
                            className="form-control"
                            // validate={{ required: { value: true }, min: { value: userPrivilege < 8 ? 10000 : 10000, errorMessage: "Minimum transaction amount should be Rs. " + (userPrivilege < 8 ? 10000 : 10000) } }}
                            validate={{ required: { value: true }, min: { value: userPrivilege < 8 ? 1000 : 1000, errorMessage: "Minimum transaction amount should be Rs. " + (userPrivilege < 8 ? 1000 : 1000) } }}
                            id="validationCustom05"
                            value={master?.fund_transfer_amount}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">Comments</Label>
                          <AvField
                            name="fund_transfer_comment"
                            placeholder="Comments"
                            type="text"
                            errorMessage="Enter Comments"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validationCustom05"
                            value={master?.fund_transfer_comment}
                            onChange={handleChangeInput}
                          />
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "26px" }}>
                        <div >
                          <Button
                            color="primary"
                            type="submit"
                            disabled={addingFundTransfer ? true : false}
                          >
                            {addingFundTransfer ? "Adding" : "Submit"}
                          </Button>
                        </div>
                      </Col>
                      <Col md="1" style={{ paddingTop: "26px" }}>
                        <div >
                          <Button
                            color="danger"
                            type="reset"
                            onClick={() => reset()}
                          >
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>

                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <AvForm>
                    <Row>
                      <Col md="3">
                        <Label>From</Label>
                        <input type="date" name="from_date" className="form-control" value={filters.from_date} onChange={handleChangeFilter} />
                      </Col>
                      <Col md="3">
                        <Label>To</Label>
                        <input type="date" name="to_date" className="form-control" value={filters.to_date} onChange={handleChangeFilter} />
                      </Col>
                      <Col md="3">
                        <Label >Transfer From</Label>
                        <ReactSelect
                          value={filters.fund_transfer_from}
                          onChange={(selectedOption) => handleSelectChange(selectedOption, "fund_transfer_from")}
                          options={fundSources} />
                      </Col>
                      <Col md="3">
                        <Label >Transfer To</Label>
                        <ReactSelect
                          value={filters.fund_transfer_to}
                          onChange={(selectedOption) => handleSelectChange(selectedOption, "fund_transfer_to")}
                          options={fundToSources}
                        />
                      </Col>
                      <Col md='12' className="d-flex justify-content-end">
                        <Button style={{ marginTop: "28px" }} color="danger" onClick={resets}>Reset</Button>
                        <Button style={{ marginTop: "28px", marginLeft: "4px" }} color="primary" tag="a"><CSVLink data={exportData} filename={getFileName()} style={{ color: 'white', textDecoration: 'none' }}>Export</CSVLink></Button>
                      </Col>
                    </Row>
                  </AvForm>
                  {loading ? (
              <div className="text-center">
                <Spinner color="primary" type="grow" /> 
                <Spinner color="info" type="grow"/> 
                <Spinner color="danger" type="grow"/> 
                <Spinner color="warning" type="grow"/> 
               
              </div>
            ):
                  <MDBDataTable
                    responsive
                    bordered
                    data={dataTableData}
                    searching={true}
                    info={false}
                    disableRetreatAfterSorting={true}
                    entries={25}
                  />
          }
                </CardBody>
              </Card>
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>
  );
};

// const mapStateToProps = (state) => {};

export default Fund;

// Users.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
